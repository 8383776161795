export default {
    home: {
      name:'心电衣',
      product:{
        title:'产品介绍',
        des:{
          a:'人工智能心电衣(AI-ECC)是一款集高科技、智能分析及用户便利性于一身的创新产品,旨在提供全方位的心脏健康监测和管理解决方案。',
          b:'通过以上设计,它为用户的心脏健康保驾护航,同时为医疗/监护人员提供了一个强大的远程监控工具。这些特点使得心电衣成为心脏健康管理领域的一个重要创新,有望改善用户的生活质量并优化健康管理实践。',
          c:'基于人体工程学的整体设计,穿戴方便且舒适、操作简单、普适性高。',
          d:'多个采集点精确测量心脏的健康数据,通过AI神经网络处理器分析结果,提高检测精度,并实时分析动态数据。'
        }
      },
      trait:{
        title:'五大特征'
      },
      scene:{
        title:'使用场景',
        little_title:'不同应用场景',
        tutelage:{
          title:'远程监护',
          des:{
            a:'心电衣将收集到的数据实时传输给医疗专业人员或指定的监护人，实现远程健康监控。',
            b:'可远程发起监控检查'
          }
        },
        real_time:{
          title:'实时心电监控',
          des:'实时监控和记录心电信号对于需要持续监测心脏状况的用户尤其重要,能够及时发现任何异常或潜在的心脏问题。全天提供至少48次心电报告。'
        },
        analyze:{
          title:'AI驱动数据分析与报告',
          des:{
            a:'先进的人工智能技术，心电衣可以自动分析心电数据，并识别出12种心脏疾病的迹象。',
            b:'可自动识别5类心律不齐',
            c:'可自动检测房颤',
            d:'可自动检测多类心肌梗死心电信号'
          },
        },
      },
      honor:{
        title:'荣誉证书'
      }
    },
    index:{
      name:'首页',
      banner:{
        title:'医疗 · 慧创健康',
        des:{
          one:'脑健康智能评估',
          two:'人工智能心电衣',
          three:'人工智能掌上超声'
        }
      },
      firm:{
        title:'关于企业',
        des:'皓世天辉控股集团是一家专注于人工智能健康医疗领域的高科技公司，致力于成为全球AI健康医疗领域的领跑者。集团旗下拥有四家子公司，分别是杭州精康科技有限公司、杭州护脉科技有限公司、杭州医策双合科技合伙企业和杭州智鸿博瑞有限公司。',
        vision_des:{
          one:{
            title:'公司愿景与使命',
            des:'皓世天辉控股集团秉承“科技引领健康未来”的理念，不断推动人工智能技术在医疗健康领域的创新与应用，为全球用户提供精准、高效的健康管理和疾病预防解决方案。',
           
          },
          two:{
            title:'技术研发与创新',
            des:'皓世天辉控股集团在核心AI技术研发和综合人工智能医疗设备制造方面拥有强大的技术实力和创新能力。公司注重与国内外顶尖科研机构和医疗机构的合作，持续推动技术进步和产品创新。',
            
          },
          three:{
            title:'企业文化',
            des:`集团倡导“以人为本、科技创新、合作共赢”的企业文化，致力于为员工提供良好的职业发展平台，与合作伙伴共同成长，共同推动全球健康医疗事业的进步。`,
            des2:'皓世天辉控股集团将继续以卓越的技术和优质的服务，努力成为全球AI健康医疗领域的标杆企业，为人类健康事业做出更大的贡献。'
          }
        },
      },
      product:{
        title:'产品介绍',
        btn:'了解详情>',
        Dynamic_EEG:{
          title:'动态脑电检测分析工作站',
          des:'动态脑电图仪面向“健康中国”和“脑科学与类脑研究”国家战略需求，在脑医学与健康工程方向开展变革性诊疗理论和方法研究，突破了心理、生理信息“感”+“知”+“疗”关键技术，我们研发出“三导脑电”精神状态量化评估技术，利用智能诊断系统、深度神经网络算法，基于三导联脑电头戴采集静息状态以及使用不同的声音范式刺激下的前额脑电信号进行深度分析，对于抑郁症、焦虑症、成瘾性等疾病的检测准确率达到90%以上......',
        },
        channels_EEG:{
          title:'三导脑电心理健康分析测评系统',
          des:'三导脑电心理健康分析测评系统面向“健康中国”和“脑科学与类脑研究”国家战略需求，在脑科学与健康工程方向开展变革性的理论和方法研究，突破了心理、生理信息“感”+“知”+“疗”关键技术，我们研发出“三导脑电”精神状态量化评估技术，利用人工智能测评系统、深度神经网络算法，基于三导联脑电头戴采集静息状态以及使用不同的声音范式刺激下的前额脑电信号进行深度分析，对于抑郁、焦虑等心理健康障碍测评准确率达到90%以上，解决了传统心理量表测评方式存在的有效指标稀缺、主观性强、精确度低、普适化弱等问题。'
        },
        color_ultrasound:{
          title:'推车彩超',
          des:'智能超声诊断仪产品整体解决方案包含甲状腺超声智能诊断系统、乳腺超声智能诊断系统等产品，利用深度神经网络算法,基于多普勒超声仪动态影像数据分析，从而实现乳腺、甲状腺等身体部位健康风险评估。 通过人工智能加持，诊断服务更加便捷，设备操作更加简单，极大减少了诊断报告等待时间。'
        },

      },
      cooperation:{
        title:'合作单位'
      },
      institution:{
        title:'已累计服务医疗机构',
        num:'100+',
        schoolList:{
          one:{
            a:'复旦大学附属肿瘤医院',
            b:'浙江大学医学院附属邵逸夫医院',
            c:'上海市第六人民医院',
            d:'广州市第一人民医院',
            e:'长沙医学院附属医院',
            f:'杭州市临安第一人民医院',
            g:'南方医科大学第三医院',
            h:'广州市番禺区中医院',
          },
          two:{
            a:'湖南省肿瘤医院',
            b:'湖南省人民医院',
            c:'南华大学附属第一医院',
            d:'湖南中医大学附属二医院',
            e:'中南大学湘雅二医院',
            f:'中南大学湘雅三医院',
            g:'宁德市闽东医院',
            h:'长沙市中心医院',
          },
          three:{
            a:'中南大学湘雅医院',
            b:'广州医科大学附属第一医院',
            c:'兰州大学第二医院',
            d:'长沙县妇幼保健院',
            e:'长沙市第八医院',
            f:'云南龙陵第一人民医院',
            g:'广州市番禺区健康管理中心',
            h:'长沙市望城区人民医院',
          },
        }
      },
      plan:{
        title:'合作共赢 - 未来规划'
      }
    },
    brain:{
      name:'脑电设备',
      technology:{
        title:'技术原理',
        des:'三导脑电心理健康分析测评系统技术创新性的以生理信号为客观指标进行精神状态评估，脑电信息经数据处理后，通过大数据模型，客观、高效地对受试者进行精神状态评估，并能对未达到临床疾病标准的亚健康及高危人群进行风险预测与诊断。'
      },
      thesis:{
        title:'论文专利',
        des_top:'发表高水平论文500余篇（2020-23全球高被引），授权专利20余项',
        des_btm:'全球首个面向抑郁障碍的多模态心理、生理数据库MODMA'
      },
      honor:{
        title:'荣誉证书',
        little_title:'临床证明100+'
      },
      introduce:{
        title:'产品介绍',
        EEG_positive:'三导脑电心理健康分析测评系统（正）',
        EEG_negative:'三导脑电心理健康分析测评系统（反）',
        Work_station:'动态脑电检测分析工作站（正）',
        Work_stations:'动态脑电检测分析工作站（反）'
      },
      cooperation:{
        title:'合作单位/实践案例'
      }
    },
    ultrasonic:{
      name:'超声',
      system:{
        title:'系统介绍',
        des:{
          a:'人工辅助诊断系统由精康科技联合南京大学、河北大学共同研发而成，智能诊断系统利用深度神经网络算法，基于多普勒超声仪动态影像数据分析，从而实现乳腺和甲状腺健康风险评估。',
          b:'人工辅助诊断系统系统采用了先进的深度神经网络算法，实现实时对多普勒超声仪动态影像数据进行分析，以实现对乳腺和甲状腺健康风险的全面评估。 通过这项技术，医生和医疗专业人员能够更加准确地诊断患者的疾病状况，从而提供更有效的治疗方案。与传统的医疗诊断方法相比，该系统不仅能够提供更准确的诊断结果，还能够在较短的时间内完成评估，从而节约了医疗资源并提高了诊疗效率。'
        },
        nerve:{
          a:{
            title:'1.深度神经网络：',
            des:{
              a:'1.1模拟医生在阅片过程中对超声影像进行观察和判断的思维过程。',
              b:'1.2通过多层神经网络的结构，自动学习并提取超声影像中的关键特征。'
            }
          },
          b:{
            title:'2.肿瘤特征评估与分级：',
            des:{
              a:'2.1深度神经网络学习和理解乳腺肿瘤的大小、形态、回声等特征。',
              b:'2.2根据肿瘤特征进行综合评估，得到 Bi-RADS 分级。'
            }
          },
          c:{
            title:'3.应用价值：',
            des:{
              a:'深度神经网络能够帮助医生更好地理解患者的乳腺状况。提供重要的参考依据，支持医生制定治疗决策。',
              b:'能够提供快速、准确的乳腺肿瘤评估和分级，提高医疗诊断效率和准确性。'
            }
          }
          
        }
      },
      diagnosis:{
        title:'诊断流程',
        des:'该系统通过超声设备，在医生扫描病人的同时，AI服务器进行实时分析和标注，在页面上标记提示，精准抓取仅毫秒级闪现的病灶，有效降低漏诊率。最后，综合分析整个扫描过程的影像数据智能生成对可疑病灶的诊断，经医生复核并打印。'
      },
      option:{
        title:'解决方案',
        li_title:'智能医疗辅助诊断工作站',
        des:{
          a:'精康提供智能诊断服务工作站，同时无需对医院设备进行改动或调整现有的工作流程。',
          b:'同时适配市面90%以上的超声仪产品，支持本地化部署、云部署和移动化部署。'
        }
      },
      noopsyche:{
        title:'人工智能超声诊断仪产品整体解决方案',
        vice_title:'人工智能掌上超声',
        des:{
          a:'智能超声诊断仪产品整体解决方案包含甲状腺超声智能诊断系统、乳腺超声智能诊断系统等产品，利用深度神经网络算法,基于多普勒超声仪动态影像数据分析，从而实现乳腺、甲状腺等身体部位健康风险评估。',
          b:'通过人工智能加持，诊断服务更加便捷，设备操作更加简单，极大减少了诊断报告等待时间。'
        },
        vice_title_des:{
          a:'1、相比传统的超声设备,手持式医用超声扫描仪体积小、重量轻,易于携带和操作；',
          b:'2、超声影像设备，精致小巧、性能强大，丰富的探头功能应用；',
          c:'3、适用于各种临床场景，随时随地提供高质量的超声影像；',
          d:'4、能够实时分析甲状腺和乳腺超声超声影像，并标注病灶位置和分类等级。',
          e:'5、更全面、更实时、更高效的诊断支持；'
        }
      },
      patent:{
        title:'权誉专利',
        literature_num:'8',
        literature:'项软件著作权',
        certificate_num:'10',
        certificate:'项专利证书',
        other:{
          a:'1项国际专利',
          b:'1项美国专利',
          c:'1项欧洲专利',
          d:'1项香港专利',
          e:'6项国内专利',
        },
        certificate_of_honor:'荣誉证书'
      }
    },
    tabs: {
      work: '作品',
      private: '私密',
      collect: '收藏',
      like: '喜欢',
    },
  }
  